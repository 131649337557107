import { gql } from '@apollo/client';
import { OrderItemFragment } from '../fragments/OrderItem';

export const REQUEST_DUTY_PACKAGE_AS_LEAD = gql`
  mutation requestDutyPackageAsLead(
    $houseId: ID!
    $request: LeadDutyPackageRequestInput!
    $journeyData: JourneyEventInput
    $partnerOfficeId: String
    $affiliate: String
    $notes: String
  ) {
    requestDutyPackageAsLead(
      houseId: $houseId
      request: $request
      journeyData: $journeyData
      partnerOfficeId: $partnerOfficeId
      affiliate: $affiliate
      notes: $notes
    ) {
      orderId
      constructionYear
      type
    }
  }
`;

export const REQUEST_DUTY_PACKAGE_AS_CUSTOMER = gql`
  mutation requestDutyPackageAsCustomer(
    $houseId: ID!
    $request: CustomerDutyPackageRequestInput!
    $journeyData: JourneyEventInput
    $notes: String
  ) {
    requestDutyPackageAsCustomer(
      houseId: $houseId
      request: $request
      journeyData: $journeyData
      notes: $notes
    ) {
      orderId
      constructionYear
      type
    }
  }
`;

export const ORDER_ITEM_BY_KEY = gql`
  ${OrderItemFragment}

  query orderItemByKey($key: String!) {
    orderItemByKey(key: $key) {
      ...OrderItem
    }
  }
`;

export const ORDER_ITEM_NAME_BY_KEY = gql`
  query orderItemNameByKey($key: String!) {
    orderItemByKey(key: $key) {
      id
      fullName
    }
  }
`;

export const ORDER_ITEM_BY_KEY_WITH_PRICE = gql`
  ${OrderItemFragment}

  query orderItemByKeyWithPrice(
    $key: String!
    $requesterId: String!
    $requesterType: String!
    $houseId: String!
  ) {
    orderItemByKey(key: $key) {
      ...OrderItem
      price(requesterId: $requesterId, requesterType: $requesterType, houseId: $houseId)
    }
  }
`;

export const ORDER_ITEMS_BY_KEYS_WITH_PRICES = gql`
  ${OrderItemFragment}

  query orderItemsByKeysWithPrices(
    $keys: [String!]!
    $requesterId: String!
    $requesterType: String!
    $houseId: String!
  ) {
    orderItemsByKeys(keys: $keys) {
      ...OrderItem
      price(requesterId: $requesterId, requesterType: $requesterType, houseId: $houseId)
    }
  }
`;

// Required, even if unused; needed for generating the DutyPackageName enum
export const ORDER_ITEM_BY_NAME = gql`
  ${OrderItemFragment}

  query orderItemByNamesWithPrices(
    $keys: [DutyPackageName!]!
    $requesterId: String!
    $requesterType: String!
    $houseId: String!
  ) {
    orderItemsByNames(keys: $keys) {
      ...OrderItem
      price(requesterId: $requesterId, requesterType: $requesterType, houseId: $houseId)
    }
  }
`;

// Intended for Samen
export const AVAILABLE_ORDER_ITEMS_BY_DOSSIER_DATA = gql`
  ${OrderItemFragment}

  query availableOrderItemsByDossierData(
    $requesterId: String!
    $requesterType: String!
    $mortgageLender: PartnerMortgageLender
    $hasGreenMortgage: Boolean
    $collective: PartnerCollectiveKey
  ) {
    availableOrderItemsByDossierData(
      requesterId: $requesterId
      requesterType: $requesterType
      mortgageLender: $mortgageLender
      hasGreenMortgage: $hasGreenMortgage
      collective: $collective
    ) {
      orderItem {
        ...OrderItem
        # TODO: would be cleaner to have price on here instead of price beside it,
        # with priceByDossier, but this saves a bunch of extra gprc calls: we get em for free like this
      }
      price
    }
  }
`;

export const ORDER_ITEM_BY_KEY_WITH_PA_PRICE = gql`
  ${OrderItemFragment}

  query orderItemByKeyWithPAPrice(
    $key: String!
    $requesterId: String!
    $requesterType: String!
    $mortgageLender: PartnerMortgageLender
    $hasGreenMortgage: Boolean
    $collective: PartnerCollectiveKey
  ) {
    orderItemByKey(key: $key) {
      ...OrderItem
      priceByDossier(
        requesterId: $requesterId
        requesterType: $requesterType
        mortgageLender: $mortgageLender
        hasGreenMortgage: $hasGreenMortgage
        collective: $collective
      )
    }
  }
`;

export const ORDER_ITEMS_BY_KEYS_WITH_PA_PRICES = gql`
  ${OrderItemFragment}

  query orderItemsByKeysWithPAPrices(
    $keys: [String!]!
    $requesterId: String!
    $requesterType: String!
    $mortgageLender: PartnerMortgageLender
    $hasGreenMortgage: Boolean
  ) {
    orderItemsByKeys(keys: $keys) {
      ...OrderItem
      priceByDossier(
        requesterId: $requesterId
        requesterType: $requesterType
        mortgageLender: $mortgageLender
        hasGreenMortgage: $hasGreenMortgage
      )
    }
  }
`;
