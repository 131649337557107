import { gql } from '@apollo/client';

export const OrderItemFragment = gql`
  fragment OrderItem on OrderItem {
    id
    created
    key
    fullName
    isSupplemental

    duties
    features

    callToAction
    customerDescription
    customerRequirements
    nextSteps
    nonDiscountedPrice

    icon
    color
    ribbon
    ribbonColor
    ribbonBgColor
    additionsColor
  }
`;
